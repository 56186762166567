import React, { useRef } from 'react'
import './contact.css'
import {MdOutlineMarkEmailUnread} from 'react-icons/md'
import {TiMessages} from 'react-icons/ti'
import { ImOpt } from 'react-icons/im'
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_dbvs7y9', 'template_mpxvlzu', form.current, 'LLRcW812OF_0ktTG-')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <div className="contact_options">
          <article className="contact_option">
            <MdOutlineMarkEmailUnread className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>sierraduong22@gmail.com</h5>
            <a href="mailto:dmh174@usf.edu">Send me a message</a>
          </article>

          <article className="contact_option">
            <TiMessages className='contact_option-icon'/>
            <h4>Messenger</h4>
            <h5>Facebook: Huong Duong</h5>
            <a href="https://m.me/mai.huong.1606.99/">Send me a message</a>
          </article>

          {/* Try to find phone number action */}
          
          {/* Create a form */}
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required />
            <input type="email" name='email' placeholder='Your Email' required />
            <textarea name="message" rows="10" placeholder='Your Message' required></textarea>
            <button type='submit' className='btn btn-primary'>Send The Message</button>
          </form>
                   
        </div>
      </div>
    </section>
  )
}

export default Contact