import React from 'react';
import './project.css'; // Assume you have corresponding CSS for styling
import CREVEA from '../../assets/Crevea.jpeg'
import MSV from '../../assets/MSV.jpeg'
import FITNESS from '../../assets/Fitness.png'
import ME from '../../assets/ME.png'

const projects = [
  {
    title: 'Flexi Fitness Application',
    description: 'A personal project about web application is designed to help users track their fitness progress, set personalized goals, and achieve a healthier lifestyle',
    technologies: ['React', 'Flask', 'JavaScript', 'PostgresSQL', 'REST API', 'AWS - Amazon Web Services', 'EmailJS'],
    role: 'Full Stack Developer',
    url: 'https://github.com/sisisi222/fitness_app',
  },
  {
    title: 'Personal Portfolio',
    description: 'A personal portfolio website showcasing my projects, skills, and contact information.',
    technologies: ['HTML', 'CSS', 'JavaScript'],
    role: 'Frontend Developer',
    url: 'https://huongduong.tech/',
  },
  {
    title: 'Crevea Online Website',
    description: 'This website showcases the versatility of front-end development with its responsive design using HTML, CSS, and Bootstrap 5, enhanced by animations, hover effects, and custom fonts for an improved user experience.',
    technologies: ['HTML', 'CSS', 'Bootstrap 5'],
    role: 'Frontend Developer',
    url: 'https://creave.online/',
  },
  {
    title: 'MSV Airline',
    description: 'Works in a group of three to create an airline website called MSV Airlines. The purpose of our website is to allow users to view information about available flights and buy the ticket',
    technologies: ['Node.js', 'Express.js', 'React', "PostgresSQL"],
    role: 'Full Stack Developer',
    url: 'https://github.com/mishanguyen/MSV-Airlines',
  },
];

const Project = () => {
  return (
    <section id="projects">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>

      <div className="container projects_container">
        {projects.map((project, index) => (
          <article key={index} className="project_item">
            <h2>{project.title}</h2>
            {project.title === 'Crevea Online Website' && (
              <div className="project_image">
                <img src={CREVEA} alt="Crevea Online Website" />
              </div>
            )}

            {project.title === 'MSV Airline' && (
              <div className="project_image">
                <img src={MSV} alt="MSV Airline" />
              </div>
            )}

            {project.title === 'Flexi Fitness Application' && (
              <div className="project_image">
                <img src={FITNESS} alt="Flexi Fitness Application" />
              </div>
            )}

            {project.title === 'Personal Portfolio' && (
              <div className="project_image">
                <img src={ME} alt="Personal Portfolio" />
              </div>
            )}

            <p>{project.description}</p>
            <div className="project_technologies">
              {project.technologies.map((tech, techIndex) => (
                <small key={techIndex}>{tech}</small>
              ))}
            </div>
            <div className="project_role"><strong>Role:</strong> {project.role}</div>
            <a href={project.url} target="_blank" rel="noopener noreferrer" className="btn btn-primary">View Project</a>
          </article>
        ))}
      </div>
    </section>
  );
};


export default Project;
