import React from 'react'
import './nav.css'
import {FaHome} from 'react-icons/fa'
import {CgUserList} from 'react-icons/cg'
import {MdOutlineWork} from 'react-icons/md'
import {RiCustomerService2Fill} from 'react-icons/ri'
import {ImProfile} from 'react-icons/im'
import {MdContactMail} from 'react-icons/md'
import { useState} from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href='#'   onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><FaHome/></a>
      <a href='#about'   onClick={() => setActiveNav('#about')}   className={activeNav === '#about' ? 'active' : ''}><CgUserList/></a>
      <a href='#experience'  onClick={() => setActiveNav('#experience')}   className={activeNav === '#experience' ? 'active' : ''}><MdOutlineWork/></a>
      <a href='#services' onClick={() => setActiveNav('#services')}   className={activeNav === '#services' ? 'active' : ''}><RiCustomerService2Fill/></a>
      <a href='#contact' onClick={() => setActiveNav('#contact')}   className={activeNav === '#contact' ? 'active' : ''}><MdContactMail/></a>

    </nav>
  )
}

export default Nav