import React from 'react'
import './service.css'
import {BsCheck2} from 'react-icons/bs'

const service = () => {
  return (
    <section id ='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container">
        <article className="service">
          <div className="service_head">
            <h3>Web Design</h3>
          </div>

          <ul className="service_list">
            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>HTML, CSS, Bootstrap 5</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>React</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>Node JS</p>
            </li>

          </ul>
        </article>




        <article className="service">
          <div className="service_head">
            <h3>Web Development</h3>
          </div>

          <ul className="service_list">
            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>JavaScript</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>Python</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>Java</p>
            </li>
          </ul>
        </article>




        <article className="service">
          <div className="service_head">
            <h3>DataBase</h3>
          </div>

          <ul className="service_list">
            <li>
            <BsCheck2 className='service_list-icon'/>
            <p>Postgres SQL</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>AWS RDS</p>
            </li>

            <li>
              <BsCheck2 className='service_list-icon'/>
              <p>MongoDB (In Progress)</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default service