import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header_socials'>
        <a href="https://www.linkedin.com/in/huong-duong-252b8816b/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/sisisi222" target="_blank"><FaGithub/></a>
    </div>
  )
}

export default HeaderSocials