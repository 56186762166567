import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/pic4new.png'
import HeaderSocials from './HeaderSocials'

const header = () => {
  return (
    <header>
      <div className="container header_container">
        <h3>Hello I am</h3>
        <h1>SIERRA DUONG</h1>
        <h3 className="text-light">Computer Science Major - Fullstack developer</h3>

        <div className='header-element'>
          <div className="cta-container"><CTA></CTA></div>
          <div><HeaderSocials></HeaderSocials></div>
        </div>

      
        <div className="me">
          <img src={ME} alt="me"/>
        </div>

        {/*<a href="#contact" className='scroll-down'>Scroll Down</a> */}
      </div>
    </header>
  )
}

export default header
