import React from 'react'
import './exp.css'
import {BsCheck2Square} from 'react-icons/bs'


const exp = () => {
  return (
    <section id = 'experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Frontend Developmet</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>HTML</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className="experience_details-icon">
                <h4>CSS</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className="experience_details-icon">
                <h4>Bootstrap 5</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className="experience_details-icon">
                <h4>React</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

          </div>
        </div>

        <div className="experience_backend">
          <h3>Backend Developmet</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>C Language</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>C++ Language</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Java</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>JavaScript</h4>
                <small className='text-light'>Intermediate </small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Python</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>
          </div>
        </div>

        <div className="experience_database">
          <h3>Database</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>PostgresSQL</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className="experience_details-icon">
                <h4>AWS RDS</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Hadoop</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Hive</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Spark</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>MongoDB</h4>
                <small className='text-light'>Beginner</small>
              </div>
            </article>

          </div>
        </div>

        <div className="experience_other">
          <h3>Others</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>PowerBI</h4>
                <small className='text-light'>Intermediate</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className="experience_details-icon">
                <h4>Microsoft Office</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Bilingual</h4>
                <small className='text-light'>English, Vietnamese</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Customer Service</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Event Coordination</h4>
                <small className='text-light'>Exprienced</small>
              </div>
            </article>

            <article className='experience_details'>
              <BsCheck2Square className='experience_details-icon'/>
              <div className='experience_details-icon'>
                <h4>Tutoring Skill</h4>
                <small className='text-light'>Certified</small>
              </div>
            </article>

          </div>
        </div>
      </div>


    </section>
  )
}

export default exp