import React from 'react'
import './about.css'
import ME from '../../assets/mypic.jpg'
import {MdOutlineWorkOutline} from 'react-icons/md'
import {GiSkills} from 'react-icons/gi'
import {BsUiChecks} from 'react-icons/bs'
import { GiCookingPot, GiConsoleController, GiTv } from 'react-icons/gi';

const funFacts = [
  { text: "I love cooking and baking", icon: <GiCookingPot /> },
  { text: "I love Pokémon movies and games", icon: <GiConsoleController /> },
  { text: "I love watching anime", icon: <GiTv /> }
];

const about = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            
            <article className='about_card'>
              <MdOutlineWorkOutline className='about_icon'/>
              <h5>Experience</h5>
              <small>2+ years working</small>
            </article>

            <article className='about_card'>
              <GiSkills className='about_icon'/>
              <h5>Skills</h5>
              <small>HTML, C, C++, React, Java, JavaScript, Python, SQL, Flask, AWS</small>
            </article>

            <article className='about_card'>
              <BsUiChecks className='about_icon'/>
              <h5>Projects</h5>
              <small>Voice Personal Assistant, Personal and Business Website, Fitness Application, MaintechBS, MSV Airline</small>
            </article>
          </div>

          <p>🌟 <strong>Welcome to my professional space!</strong> I'm Sierra Duong, a dedicated computer science student on the lookout for a challenging internship opportunity.</p>
          <p>
            <ul>
                <li>🎓 Currently pursuing a <strong>Bachelor of Science in Computer Science</strong> at the University of South Florida, maintaining an impressive <strong>3.8 GPA</strong>.</li>
                <br></br>
                <li>💼 I bring hands-on experience as a <strong>Help Desk Specialist</strong>, where I enhanced service satisfaction by <strong>50%</strong> and supported academic success through technology.</li>
                <br></br>
                <li>📚 Proven track record as a <strong>Math Tutor</strong>, where my instructional strategies led to a <strong>30% average increase</strong> in student grades.</li>
                <br></br>
                <li>🛠️ Proficient in multiple programming languages and frameworks such as <strong>Java, C, C++, HTML/CSS, JavaScript, Python, SQL, React, Node.js,</strong> and <strong>Flask</strong>.</li>
                <br></br>
                <li>🏆 An honored member of the <strong>Phi Theta Kappa Honor Society</strong> and a <strong>Certified Tutor</strong> by the International Tutor Training Program.</li>
                <br></br>
                <li>🌐 Fluent in <strong>English and Vietnamese</strong>, enhancing team communication and cross-cultural collaboration.</li>
                <br></br>
                <li>🚀 Led and supported key projects that showcase my ability to apply complex concepts in <strong>software development</strong> and <strong>data management</strong>.</li>
            </ul>
          </p>
            
          <p>I am enthusiastic about contributing my skills and learning more as a software engineer. Let's explore how we can collaborate!</p>

          <div className="fun_facts">
            <h4>Fun Facts</h4>
            <ul>
                {funFacts.map((fact, index) => (
                    <li key={index}>
                        {fact.icon}
                        <span>{fact.text}</span>
                    </li>
                ))}
            </ul>
          </div>

          <br></br>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default about